<template>
  <div class="container flex">
    <div class="leftMenu">
      <div
        class="leftMenuItem"
        :class="{ active: item.value == nwoMenu.value }"
        v-for="(item, index) in menuList"
        @click="router.push(item.value)"
        :key="index"
      >
        <span>{{ item.label }}</span>
      </div>
    </div>
    <div class="rightMain flex-col">
      <div class="pageTitle flex">
        <h2>{{ nwoMenu.label }}</h2>
      </div>
      <div class="mainContainer flex-1">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, getCurrentInstance, computed } from "vue";
import { useRouter } from "@/utils/vueApi";
const router = useRouter();
const { proxy } = getCurrentInstance();
const menuList = ref([
  {
    label: proxy.$t("headerText.memberCenter"),
    value: "/innerPage/memberCenter",
  },
  {
    label: proxy.$t("headerText.investmentStatistics"),
    value: "/investmentStatistics",
  },
  {
    label: proxy.$t("headerText.bankCard"),
    value: "/bankWallet",
  },
  {
    label: proxy.$t("headerText.helpCenter"),
    value: "/innerPage/heloCenter?id=35",
  },
  {
    label: proxy.$t("headerText.aboutSelf"),
    value: "/innerPage/heloCenter?id=19",
  },
  {
    label: proxy.$t("headerText.accountSet"),
    value: "/innerPage/accountSettings",
  },
]);
// 当前菜单
const nwoMenu = computed(() => {
  // 根据路由地址获取标题
  return menuList.value.find((item) => item.value === proxy.$route.fullPath);
});
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  height: 100%;
  .leftMenu {
    width: 270px;
    height: 100%;
    background: $section-color;
    overflow-y: auto;
    border-right: 1px solid var(--border-color-top);
    .leftMenuItem {
      width: 100%;
      height: 48px;
      display: flex;
      align-items: center;
      padding-left: 20px;
      box-sizing: border-box;
      cursor: pointer;
      &.active {
        border-left: 4px solid #5670f6;
        background-color: var(--hover-color);
        font-weight: bold;
      }
    }
  }
  .rightMain {
    flex: 1;
    width: 10px;
    overflow-y: auto;
    .pageTitle {
      min-height: 72px;
      width: 100%;
      background: $section-color;
      align-items: center;
      padding-left: 24px;
      box-sizing: border-box;
    }
    .mainContainer {
      padding: 15px 25px;
      box-sizing: border-box;
      height: auto;
    }
  }
}
</style>
